<template>
  <div class="disclosure">
    <h1 class="sr-only">Promo-disclosure</h1>
    <h2 class="headline1">Promotion Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of July 12, 2021</strong>
    </p>
    <p>
      Electrify America is pleased to provide this promotional charging program. By clicking “Apply this code” to
      confirm your submission of the promotional code that you entered in the mobile app, you agree to participate in
      this promotion, subject to your eligibility and your compliance with these terms and conditions.
    </p>
    <p>
      Immediately upon your confirmation of the promotional code and acceptance of these terms, you will receive a
      special discount on charging sessions initiated through the Electrify America app. The parameters of this discount
      vary depending on the type of promotion associated with each code, as indicated in the “Description” field of the
      “Verify promo code” screen of your app. This discount will continue until the Expiration Date specified in your
      app, unless the promotion is earlier terminated pursuant to these terms and conditions.
    </p>
    <p>The different discount types are as follows:</p>
    <p>
      <strong>“[$] off [#] sessions”</strong> - You will automatically receive the dollar amount discount on each
      session up to the specified number of charging sessions until the specified Expiration Date. Please note that each
      session to which the discount is applied will count toward the total number of sessions, even if the total cost of
      the session is less than the full discount amount.
    </p>
    <p>
      <strong>“[$] off all sessions until [date]”</strong> - You will automatically receive the dollar amount discount
      on all charging sessions until the specified Expiration Date.
    </p>
    <p>
      <strong>“[%] off [#] sessions”</strong> - The percentage discount will apply to each session up to the specified
      number of charging sessions until the specified Expiration Date. Please note that each session to which the
      discount is applied will count toward the total number of sessions.
    </p>
    <p>
      <strong>“[%] off all sessions until [date]”</strong> - The percentage discount will apply to all charging sessions
      until the specified Expiration Date.
    </p>
    <p>
      By applying the promotional code to your account, you acknowledge and accept the above terms for the promotion
      type displayed in your app.
    </p>
    <p>
      You can receive the discount at any Electrify America charging station, exclusively by using your Electrify
      America plan. This discount may not be initiated using the interface screen or buttons on the charger.
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this promotion, other than your
      continued subscription to the charging plan to which the promotional code has been applied.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      This discount is applied to the net price of the charging session (including any idle fees incurred), before tax
      is calculated.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the vehicle when charging
      at an Electrify America charging station.
    </p>
    <p>
      Except as otherwise expressly agreed by Electrify America in writing, you may <strong>not</strong> use this
      discount in connection with any taxi, ride-share (e.g., Uber or Lyft), or other similar service, or for any other
      commercial purpose. <strong>This promotion is for your personal, non-commercial use only.</strong>
    </p>
    <p>
      Once this promotion has expired, you will not be able to resubmit, reactivate, or reapply the promotional code to
      your account. Please refer to the information on the <strong>“Plan Details”</strong> section of the app to keep
      track of the amount of time and/or charging sessions remaining in this promotion.
    </p>
    <p>
      These discounts are not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. For dollar amount discounts, if you do not use the full dollar
      value of the discount during a charging session, you will not be entitled to any cash or credit refund or
      disbursement of the difference, and you cannot apply unused discount amounts from one charging session to future
      charging sessions. You may not transfer any portion of the discounts to any other Electrify America account. Only
      one promotional code may be active for any plan at a given time.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about payments and other transactions related to each charging session.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will notify you by in-app
      notification, email, or other communication in the event of any change to the scheduled end date of this
      promotion. Following the termination or expiration of this program for any reason, your access to the
      complimentary promotion will immediately cease, and you may not use or redeem any further discounts.
    </p>
  </div>
</template>

<script>
export default {
  name: 'promo-disclosure',
  metaInfo: {
    title: 'Promo Disclosure  | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/promo-disclosure/' }],
  },
};
</script>
